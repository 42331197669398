
class materialSelect extends Component {

    static name() {
        return "material-select";
    }

    static componentName() {
        return "material-select";
    }

    getProps() {
        return ['value','label','options'];
    }

    getWatch() {
        return {
            value: function (value) {
                this.reload(value);
            }
        };
    }

    mounted() {
        return function () {
            var vm = this;
            var select = $(this.$el);
            select.val(this.value)
                .on('change', function () {
                    console.log('Test Event');
                    vm.$emit('input', this.value);
                });
            //select.material_select();
        };
    }

    updated() {
        return function () {
            this.reload();
        };
    }

    destroyed() {
        return function () {
            $(this.$el).material_select('destroy');
        };
    }

    getMethods() {
        return {
            reload : function (value) {
                var select = $(this.$el);
                select.val(value || this.value);
                //.material_select('destroy');
                //select.material_select();
            }
        };
    }


    getTemplate() {
        return  `<select class="icons" @keydown.prevent="" @keydown.prevent="" @keyup.prevent="" >
                    <option value="" >{{tr('Select One')}}</option>
                    <option :value="option.value" v-for="option in options">{{tr(option.label)}}</option>
                </select>`;
    }
}

materialSelect.registerComponent();
